<template>
  <div>
    <div class="part1">
      <div class="content_fa">
        <div class="content">
          <div class="title">测量大师介绍</div>
          <div class="info">
            测量大师适用于门窗、全屋定制行业的门店使用，主要针对于行业头部玩家，以智能量尺、还原现场为出发点，从售前、售中、售后三大环节为门店提供营销获客、测量设计、入户安装、售后维保等一系列产品解决方案，并打通工厂与门店的供需关系，从经销商到工厂到测量安装人员到终端客户，帮助实现测量不出错、设计不出错、安装不出错，解决不重复沟通、不重复跑现场、让人不等时间、时间不等人的行业痛点。
          </div>
          <b-img class="pic" :src="require('../../assets/img/production/pic3.png')"></b-img>
          <div class="title">产品版本</div>
          <div class="item">
            <div class="item_title">
              v{{ appDetail.version_name }} |
              {{
                appDetail.created_at ? appDetail.created_at.split(" ")[0] : ""
              }}更新
            </div>
            <div class="item_info">
              <div class="item_info_content">
                <div v-for="(item, index) in appDetail.info" v-show="index < 3 || isShow" :key="index">
                  {{ item }}
                </div>
              </div>
              <div class="more">
                <b-button pill v-show="appDetail.info.length > 3" @click="toggleShow" variant="outline-light">
                  {{ showName }}</b-button>
              </div>
            </div>
          </div>
          <div class="getEwm">
            <div class="ewm">
              <b-img :src="require('../../assets/img/production/QRCode.png')"></b-img>
              <div>扫码下载</div>
            </div>
            <div class="down">
              <b-button href="https://mencc.oss-cn-hangzhou.aliyuncs.com/measurement/2019-12/measurement.apk" pill
                variant="light">
                <i class="iconfont icon-anzhuo"></i>
                安卓版下载
              </b-button>
              <b-button href="https://apps.apple.com/cn/app/id1504587811" target="_blank" pill variant="light">
                <i class="iconfont icon-pingguo"></i>
                IOS版下载
              </b-button>
            </div>
            <div class="look">
              <b-button pill variant="outline-light" class="" @click="toVersion">查看历史版本</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part2">
      <div class="title">测量大师产品版本介绍</div>
      <div class="fu_title">测量大师全国统一服务热线：0510-85186908</div>
      
      <!--<b-table-simple bordered>
        <b-thead>
          <b-tr>
            <b-th class="top_th">
              <b-img :src="require('../../assets/img/production/icon.png')"></b-img>
              <div class="info">
                若无法满足您的需求，我们还可以根据您的需求进行定制！
              </div>
              <div class="tips">咨询电话：0510-85186908</div>
              <b-button pill @click="showPhone" variant="outline-primary">立即定制</b-button>
            </b-th>
            <b-th class="top_th other" v-for="item in tablsit" :key="item.id">
              <!~~ 门店版 ~~>
              <div v-if="item.id =='42f6bc40-fba8-11ea-bb14-1da9f8cd21aa'">
                <div class="top_th_title">{{item.name}}</div>
                <div class="info">
                  <span v-if="item.note">{{item.note}}</span>
                </div>
                <div class="price"><span>￥</span>{{item.money}}</div>
                <div class="tips">免首年费，之后每年{{item.continue_per_year_money}}元年费</div>
                <b-button pill @click="show(2)" variant="primary">观看演示</b-button>
              </div>
              <!~~ 大店版 ~~>
              <div v-else-if="item.id =='42f6bc40-fba8-11ea-bb14-1da9f8cd22aa'">
                <div class="kong"></div>
                <div class="top_th_title sp">{{item.name}}</div>
                <div class="info">
                  <span v-if="item.note">{{item.note}}</span>
                </div>
                <div class="price"><span>￥</span>{{item.money}}</div>
                <div class="tips">免首年费，之后每年{{item.continue_per_year_money}}元年费</div>
                <b-button pill @click="show(2)" variant="primary">观看演示</b-button>
                <div class="hot">
                  <b-img :src="require('../../assets/img/production/hot.png')"></b-img>
                </div>
              </div>
              <!~~ 工厂版 ~~>
              <div v-else-if="item.id =='42f6bc40-fba8-11ea-bb14-1da9f8cd20ac'">
                <div class="top_th_title sp">{{item.name}}</div>
                <div class="info">
                  <span v-if="item.note">{{item.note}}</span>
                </div>


                <b-button pill @click="show(2)" variant="primary">观看演示</b-button>
                <div class="tips">当前仅支持与新格尔自己的CC系统互通
                  其他系统需要软件方做技术支持</div>

              </div>
            </b-th>

          </b-tr>
        </b-thead>
        <b-tbody>

          <b-tr>
            <b-th>VR全景相机</b-th>
            <b-td>不支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <!~~ <b-td rowspan="20">拥有门店版的所有功能，支持测量大师下单到工厂，工厂进行接单、报价、产品系列管理、知识库管理、经销商管理以及智能报表分析打印等功能。</b-td> ~~>
          </b-tr>
          <b-tr>

            <b-th>项目数量</b-th>
            <b-td>无限</b-td>
            <b-td>无限</b-td>
            <b-td>无限</b-td>
            <!~~ <b-td rowspan="20">拥有门店版的所有功能，支持测量大师下单到工厂，工厂进行接单、报价、产品系列管理、知识库管理、经销商管理以及智能报表分析打印等功能。</b-td> ~~>
          </b-tr>
          <b-tr>
            <b-th>找测量/安装服务</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>项目管理</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>拍照测量</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>裸测</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>数据云存储</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>入户勘查</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>门窗/柜体设计</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>安装</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>方案书</b-th>
            <b-td class="unsupport">不支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>业主报价</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>实景搭配</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>客户管理</b-th>
            <b-td class="unsupport">不支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>项目协作</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>任务管理</b-th>
            <b-td class="unsupport">不支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>超期预警</b-th>
            <b-td class="unsupport">不支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>品牌案例</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>获客展业</b-th>
            <b-td class="unsupport">不支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>经销商管理后台</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>门店小程序</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <!~~ <b-tr>
            <b-th>项目永久储存</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>智能量尺</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>AI测量本</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>现场还原</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>门窗设计</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>安装搭配现场</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>项目案例</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>项目管理</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>业主报价</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>营销获客</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>数据分析</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>智能客户画像</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>生产下单</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>超期预警</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>测量单打印</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr>
          <b-tr>
            <b-th>第三方系统对接</b-th>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
            <b-td>支持</b-td>
          </b-tr> ~~>
        </b-tbody>
      </b-table-simple>-->
      <div class="production">
        <div class="production_head">
          <div class="left">
            <b-img :src="require('../../assets/img/production/icon.png')"></b-img>
          </div>
          <div class="right">
            <div class="info">
              若无法满足您的需求，我们还可以根据您的需求进行定制！
            </div>
            <div class="tips">咨询电话：0510-85186908</div>
            <div class="right_bottom">
              <b-button pill variant="outline-primary">立即定制</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="production" v-for="item in tablsit" :key="item.id">

        <div class="production_model">
          <div class="top">
            <div class="left">
              {{item.name}}
            </div>
            <div class="right">
              <div class="info">
                {{item.note}}
              </div>
              <div class="price" v-if="item.id !='42f6bc40-fba8-11ea-bb14-1da9f8cd20ac'">
                <span>￥</span>{{item.money}}</div>
              <div class="tips" v-if="item.id !='42f6bc40-fba8-11ea-bb14-1da9f8cd20ac'">
                免首年费，之后每年{{item.continue_per_year_money}}元年费</div>
              <div class="tips" v-if="item.id =='42f6bc40-fba8-11ea-bb14-1da9f8cd20ac'">当前仅支持与新格尔自己的CC系统互通
                其他系统需要软件方做技术支持</div>
              <div class="right_bottom">
                <b-button pill @click="show(2)" variant="primary">观看演示</b-button>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="left">
              <div v-for="(itelist,index) in item.product_version_function" :key="index">
                <div
                  v-if="index < (item.product_version_function.length%2==1?item.product_version_function.length/2+0.5:item.product_version_function.length/2)"
                  class="item">
                  {{itelist.name}}：<span>支持</span></div>
              </div>
            </div>
            <div class="right">
              <div class="item">
                <div v-for="(itelist,index) in item.product_version_function" :key="index">
                  <div class="item"
                    v-if="index > (item.product_version_function.length%2==1?item.product_version_function.length/2-0.5:item.product_version_function.length/2-1)">
                    {{itelist.name}}：<span>支持</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <foot></foot>
    <right ref="right"></right>
    <myhead :background="'transparent'"></myhead>
    <b-modal id="play" hide-footer title="">
      <player ref="play" :video-url="videoUrl" :state="state"></player>
    </b-modal>
  </div>
</template>

<script>
  import player from "@/components/player.vue";
  import myhead from "@/components/head.vue";
  import foot from "@/components/foot.vue";
  import right from "@/components/right.vue";
  export default {
    name: "solution",
    data() {
      return {
        state: "",
        tablsit: [],
        videoUrl: "",
        screenWidth: document.body.clientWidth, // 屏幕尺寸
        appDetail: {
          info: []
        },
        isShow: false
      };
    },
    components: {
      foot,
      right,
      myhead,
      player
    },
    mounted() {
      this.appinfo();
      this.getversions();
    },
    computed: {
      showName() {
        if (this.isShow) {
          return "收起";
        } else {
          return "展开";
        }
      }
    },
    methods: {
      toggleShow() {
        this.isShow = !this.isShow;
      },
      show(num) {
        // 2,3,4暂时没视频，有了再把下面限制代码删除
        if (num > 1) {
          return false;
        }
        this.$bvModal.show("play");
        switch (num) {
          case 1:
            this.videoUrl =
              "https://celiang.oss-cn-hangzhou.aliyuncs.com/video_tutorial/measure_basic_show.mp4";
            break;
          case 2:
            this.videoUrl =
              "http://celiang.oss-cn-hangzhou.aliyuncs.com/measurement_video/2020-08/24/vnjE6phpMg2YZr1598232727200824.mp4";
            break;
          case 3:
            this.videoUrl =
              "https://celiang.oss-cn-hangzhou.aliyuncs.com/video_tutorial/me-mobile.thinkerx.com-introduction-%E6%B5%8B%E9%87%8F%E5%A4%A7%E5%B8%88%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D.mp4";
            break;
          case 4:
            this.videoUrl =
              "http://celiang.oss-cn-hangzhou.aliyuncs.com/measurement_video/2020-08/24/vnjE6phpMg2YZr1598232727200824.mp4";
            break;
          default:
            break;
        }

        setTimeout(() => {
          this.$refs.play.myplay();
        }, 10);
      },
      toVersion() {
        this.$router.push({
          name: "Version"
        });
      },
      showPhone() {
        this.$refs.right.showPhone();
      },
      getversions() {
        // product-versions
        this.$axios
          .get(`${this.$Tools.Conts.domain}product-versions`)
          .then(res => {
            if (res.data.code === 0) {
              var lsit = [];
              res.data.data.map(item => {
                if (item.id == "42f6bc40-fba8-11ea-bb14-1da9f8cd20ac" || item.id ==
                  "42f6bc40-fba8-11ea-bb14-1da9f8cd21aa" ||
                  item.id ==
                  "42f6bc40-fba8-11ea-bb14-1da9f8cd22aa") {
                  lsit.push(item)
                }
              })
              this.tablsit = lsit;
              // console.log(this.tablsit)
            } else {
              this.$toast.warn({
                message: res.data.message,
                timeOut: 2000
              });
            }
          });
      },
      appinfo() {
        let params = {
          with_paginate: 1,
          page: 1
        };
        this.$axios
          .get(`${this.$Tools.Conts.domain}versions`, {
            params
          })
          .then(res => {
            if (res.data.code == 0) {
              let object = res.data.data.data[0];

              object.info = object.content.split("\n");
              this.appDetail = object;

              if (res.data.data.data.length == 0) {
                this.$toast.warn({
                  message: "暂无更多数据",
                  timeOut: 2000
                });
              }
            } else {
              this.$toast.warn({
                message: res.data.message,
                timeOut: 2000
              });
            }
          });
      }
    }
  };
</script>

<style lang="less" scoped>
  .part1 {
    background: url("../../assets/img/production/bac.png");
    background-size: cover;

    .content_fa {
      padding-top: 4rem;
      padding-bottom: 12rem;
      background: rgba(0, 0, 0, 0.1);
    }

    .content {
      color: white;
      padding: 1rem;
      position: relative;

      .title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
      }

      .info {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.8);
      }

      .item {
        .item_title {
          font-size: 0.9rem;
        }

        .item_info {
          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.8);

          .more {
            padding: 0.5rem 2rem;

            .btn {
              font-size: 0.7rem;
              padding: 0.2rem 1rem;
              box-shadow: none;
            }
          }
        }
      }

      .getEwm {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
        padding: 0 1rem;
        font-size: 0.8rem;

        .ewm {
          text-align: center;
          margin-right: 1rem;

          img {
            width: 80px;
            height: 80px;
            margin-bottom: 0.5rem;
          }
        }

        .btn {
          font-size: 0.8rem;
          margin-bottom: 1rem;
        }

        .down {
          width: 8rem;
          margin-right: 1rem;

          .btn {
            color: #2b85e4;
            color: #2b85e4;
            padding: 0.4rem 0rem;
            width: 8rem;
          }
        }

        .look {
          .btn {
            padding: 0.5rem 1rem;
            width: 3rem;
          }
        }
      }

      .pic {
        display: block;
        width: 80%;
        margin: auto;
        padding-top: 1rem;
      }
    }
  }

  .part2 {
    margin-top: -9rem;
    padding-bottom: 2rem;

    .title {
      font-size: 1.2rem;
      text-align: center;
      color: white;
    }

    .fu_title {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      margin-bottom: 3rem;
    }

    .table {
      display: none;
    }

    .production {
      .production_head {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background: white;
        display: flex;
        padding: 1rem;
        border-radius: 3px;
        margin-bottom: 1rem;

        .left {
          flex-shrink: 0;
          margin: 0 0.5rem;
          padding-top: 0.5rem;

          img {
            width: 3rem;
          }
        }

        .right {
          color: #777777;
          font-size: 0.7rem;

          .info {
            padding-bottom: 0.5rem;
          }

          .tips {
            padding-bottom: 1rem;
          }

          .right_bottom {
            display: flex;
            flex-direction: row-reverse;

            .btn {
              font-size: 0.8rem;
              padding: 0.4rem 1.5rem;
            }
          }
        }
      }

      .production_model {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background: white;
        border-radius: 3px;
        margin-bottom: 1rem;

        .top {
          display: flex;
          padding: 1rem;
          border-bottom: 1px solid #c4c4c4;
          position: relative;

          .hot {
            position: absolute;
            left: 0;
            top: 0;

            img {
              width: 3rem;
            }
          }

          .left {
            flex-shrink: 0;
            font-size: 1.2rem;
            padding: 0 1.4rem;
            width: 4rem;
            line-height: 1.5rem;
          }

          .right {
            color: #777777;
            font-size: 0.7rem;

            .info {
              padding-bottom: 0.5rem;
            }

            .tips {
              padding-bottom: 1rem;

              &.first {
                padding-bottom: 0.2rem;
              }

              &.last {
                color: #aaaaaa;
              }
            }

            .price {
              font-size: 1.3rem;
              color: #007bff;
              font-weight: 600;

              span {
                font-size: 1rem;
              }
            }

            .right_bottom {
              display: flex;
              flex-direction: row-reverse;

              .btn {
                font-size: 0.8rem;
                padding: 0.4rem 1.5rem;
              }
            }
          }
        }

        .bottom {
          padding: 1rem 0;
          display: flex;
          font-size: 0.7rem;
          color: #333333;

          &>div {
            flex: 50%;
            padding: 0 1rem;

            .item {
              padding-bottom: 0.8rem;

              .unsupport {
                color: #999999;
              }
            }
          }

          .left {
            border-right: 1px solid #c4c4c4;
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    .part1 {
      background: url("../../assets/img/production/bac.png");
      background-size: cover;

      .content_fa {
        padding: 10rem 10rem 25rem;
        background: rgba(0, 0, 0, 0.1);
      }

      .content {
        color: white;
        padding: 0rem;
        position: relative;
        width: 50%;

        .title {
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
          margin-top: 1rem;
        }

        .info {
          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.8);
        }

        .item {
          .item_title {
            font-size: 1rem;
          }

          .item_info {
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.8);
          }
        }

        .getEwm {
          display: flex;
          align-items: center;
          margin-top: 1.5rem;
          padding: 0 1rem;
          font-size: 1rem;

          .ewm {
            text-align: center;
            margin-right: 1rem;

            img {
              width: 80px;
              height: 80px;
              margin-bottom: 0.5rem;
            }
          }

          .btn {
            font-size: 0.8rem;
            margin-bottom: 1rem;
          }

          .down {
            width: 8rem;
            margin-right: 1rem;

            .btn {
              color: #2b85e4;
              color: #2b85e4;
              padding: 0.4rem 0rem;
              width: 8rem;
            }
          }

          .look {
            .btn {
              padding: 0.5rem 1.5rem;
              width: auto;
            }
          }
        }

        .pic {
          display: block;
          width: 100%;
          position: absolute;
          right: -100%;
          top: 1.5rem;
        }
      }
    }

    .part2 {
      margin-top: -18rem;
      padding: 0rem 10rem 2rem;

      .title {
        font-size: 2rem;
        text-align: center;
        color: white;
      }

      .fu_title {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        margin-bottom: 5rem;
      }

      .table {
        display: table;
        background: white;
        box-shadow: 0px 1px 4px #d9d9d9;
        // max-width: 1200px;

        margin: auto;

        tr {
          &>th:first-child {
            background: #f5f5f5;
          }
        }

        th {
          font-weight: 400;
        }

        tbody {

          td,
          th {
            padding: 1.2rem 3rem 1.2rem 3rem;
            text-align: center;
            font-size: 14px;
          }
        }

        .tips_other {
          font-size: 12px;
        }

        .unsupport {
          color: #999999;
        }

        .top_th {
          font-weight: 400;
          text-align: center;
          width: 20%;
          font-size: 0.8rem;
          color: #969696;
          vertical-align: top;
          position: relative;

          .hot {
            position: absolute;
            right: 0rem;
            top: -5.5rem;
          }

          .kong {
            width: 100%;
            height: 2.5rem;
            position: absolute;
            left: 0;
            top: -2.5rem;
            background: white;
          }

          img {
            width: 60px;
            height: 60px;
            margin: 3rem auto 1rem;
          }

          .info {
            padding: 0 1rem 0.5rem;
          }

          .btn {
            padding: 0.5rem 2rem;
            font-size: 0.8rem;
            margin: 1rem auto;
          }

          .top_th_title {
            font-size: 1.2rem;
            color: black;
            margin: 1rem auto;

            &.sp {
              margin: 0rem auto 2rem;
            }
          }

          .price {
            font-size: 1.5rem;
            color: #007bff;
            font-weight: 600;

            span {
              font-size: 1rem;
            }
          }

          &.other {
            .info {
              min-height: 9.5rem;
            }
          }
        }
      }

      .production {
        display: none;
      }
    }
  }
</style>